var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner"},[_c('div',{staticStyle:{"padding":"10px"}},[_c('el-alert',{attrs:{"title":"当前页面功能已不支持，请前往新的功能页面","type":"error","show-icon":""}})],1),_c('warning-tip',{attrs:{"code":"SPTSSPPJ"}}),_c('main-top',{attrs:{"title":"商品评价","desc":"在线查询天猫、淘宝商品宝贝类目评价。","spend":"SPTSSPPJ"}},[_c('InputView',{attrs:{"extractProductId":true,"CanYouClick":_vm.CanYouClick,"placeholder-name":"请输入您的宝贝链接或宝贝ID"},on:{"sendSearchName":_vm.getSearchName}})],1),_c('bordure-row',{staticStyle:{"margin-bottom":"30px"},attrs:{"title":"商品信息","shadow":""}},[_c('div',{staticClass:"bannerContent",staticStyle:{"padding-bottom":"20px"}},[(_vm.goodsEntity[0].picUrl == '')?_c('div',{staticClass:"img_floor"}):_c('img',{staticStyle:{"dispaly":"block","width":"100px","height":"100px"},attrs:{"src":_vm.goodsEntity[0].picUrl,"alt":"图片加载中"}}),_c('div',{staticStyle:{"margin-left":"16px"}},[_c('p',{staticStyle:{"font-size":"14px","color":"#333333","letter-spacing":"0","margin":"6px 0"}},[_vm._v(" "+_vm._s(_vm.goodsEntity[0].goodsName)+" ")]),_c('p',{staticStyle:{"font-size":"12px","color":"#666666","letter-spacing":"0","margin-bottom":"16px"}},[_vm._v(" "+_vm._s(_vm.goodsEntity[0].nick)+" ")]),_c('p',{staticStyle:{"font-size":"14px","color":"#333333","letter-spacing":"0"}},[_vm._v(" "+_vm._s(_vm.goodsEntity[0].price)+" ")])])]),_c('div',[_c('el-table',{staticStyle:{"width":"100%","border":"1px solid #f4f4f4"},attrs:{"data":_vm.goodsFeedList,"size":"mini","header-cell-style":{
            background: '#f8f8f8',
            'font-family': 'PingFangSC-Regular',
            color: '#666666 !important',
            'letter-spacing': '0',
            'text-align': 'center',
            'font-weight': 'normal',
          }}},[_c('div',{staticClass:"table-in"},[_c('el-table-column',{attrs:{"align":"center","prop":"feedback","label":"评价内容","width":"200"}}),_c('el-table-column',{attrs:{"align":"center","prop":"auctionNumId","label":"宝贝id","width":"120"}}),_c('el-table-column',{attrs:{"align":"center","prop":"skuId","label":"skuId","width":"130"}}),_c('el-table-column',{attrs:{"align":"center","prop":"userNick","label":"买家昵称","width":"100"}}),_c('el-table-column',{attrs:{"align":"center","prop":"skuMap","label":"SKU"}}),_c('el-table-column',{attrs:{"align":"center","prop":"feedbackDate","label":"日期","width":"130"}})],1)]),_c('el-pagination',{staticClass:"pagination",attrs:{"current-page":_vm.currentPage,"page-size":20,"layout":"total, prev, pager, next,jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)]),_c('Introduce',{attrs:{"introduce":_vm.introduce}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }