<template>
  <div>
    <div class="banner">
      <div style="padding:10px;">
      <el-alert
        title="当前页面功能已不支持，请前往新的功能页面"
        type="error"
        show-icon>
      </el-alert>
      </div>
      
      <warning-tip code="SPTSSPPJ"></warning-tip>

      <main-top
        title="商品评价"
        desc="在线查询天猫、淘宝商品宝贝类目评价。"
        spend="SPTSSPPJ"
      >
        <!-- 使用搜索框组件 -->
        <InputView
            :extractProductId="true"
            :CanYouClick="CanYouClick"
            placeholder-name="请输入您的宝贝链接或宝贝ID"
            @sendSearchName="getSearchName"
          ></InputView>
      </main-top>

      <bordure-row title="商品信息" shadow style="margin-bottom: 30px">
        <div class="bannerContent" style="padding-bottom: 20px">
          <div class="img_floor" v-if="goodsEntity[0].picUrl == ''"></div>
          <img
            v-else
            :src="goodsEntity[0].picUrl"
            style="dispaly: block; width: 100px; height: 100px"
            alt="图片加载中"
          />
          <div style="margin-left: 16px">
            <p
              style="
                font-size: 14px;
                color: #333333;
                letter-spacing: 0;
                margin: 6px 0;
              "
            >
              {{ goodsEntity[0].goodsName }}
            </p>
            <p
              style="
                font-size: 12px;
                color: #666666;
                letter-spacing: 0;
                margin-bottom: 16px;
              "
            >
              {{ goodsEntity[0].nick }}
            </p>
            <p style="font-size: 14px; color: #333333; letter-spacing: 0">
              {{ goodsEntity[0].price }}
            </p>
          </div>
        </div>

        <!-- 以下部位为表格 -->
        <div>
          <el-table
            :data="goodsFeedList"
            style="width: 100%; border: 1px solid #f4f4f4"
            size="mini"
            :header-cell-style="{
              background: '#f8f8f8',
              'font-family': 'PingFangSC-Regular',
              color: '#666666 !important',
              'letter-spacing': '0',
              'text-align': 'center',
              'font-weight': 'normal',
            }"
          >
            <div class="table-in">
              <el-table-column
                align="center"
                prop="feedback"
                label="评价内容"
                width="200"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="auctionNumId"
                label="宝贝id"
                width="120"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="skuId"
                label="skuId"
                width="130"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="userNick"
                label="买家昵称"
                width="100"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="skuMap"
                label="SKU"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="feedbackDate"
                label="日期"
                width="130"
              ></el-table-column>
            </div>
          </el-table>
          <!-- 以下部分为分页器 -->
          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="20"
            layout="total, prev, pager, next,jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </bordure-row>

      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>

<script>
import { getGoodsFeedList, goodsSkuCount } from "../../request/api"; //这里是引入请求
import InputView from "../public/InputView";
import Introduce from "../public/Introduce";
export default {
  data() {
    return {
      value: "",
      CanYouClick: true, //搜索框是否允许点击
      goodsEntity: [
        {
          picUrl:
            "//img.alicdn.com/imgextra/i1/2201297390627/O1CN01tM2mIw1GVCz4I2lqC_!!2201297390627.jpg",
          goodsName:
            "动力火车酒鸡尾酒套装洋酒调酒基酒混合300ml*24瓶整箱苏打酒正品（模板）",
          nick: "动力火车酒类旗舰店（模板）",
          price: "578.00（模板）",
        },
      ], //相关信息列表
      goodsFeedList: [
        {
          feedback: "此用户没有填写评价。",
          userNick: "t**2",
          skuMap: "颜色分类：24瓶混合（纯情+冷艳+诱惑+狂野+浪漫+梦幻各4瓶）",
          feedbackDate: "2021-02-18",
          auctionNumId: "596446991976",
          skuId: "4590028019705",
        },
        {
          feedback: "此用户没有填写评价。",
          userNick: "t**2",
          skuMap: "颜色分类：24瓶混合（纯情+冷艳+诱惑+狂野+浪漫+梦幻各4瓶）",
          feedbackDate: "2021-02-18",
          auctionNumId: "596446991976",
          skuId: "4590028019705",
        },
        {
          feedback: "此用户没有填写评价。",
          userNick: "s**7",
          skuMap: "颜色分类：24瓶混合（纯情+冷艳+诱惑+狂野+浪漫+梦幻各4瓶）",
          feedbackDate: "2021-02-15",
          auctionNumId: "596446991976",
          skuId: "4590028019705",
        },
        {
          feedback: "此用户没有填写评价。",
          userNick: "f**i",
          skuMap: "颜色分类：24瓶混合（纯情+冷艳+诱惑+狂野+浪漫+梦幻各4瓶）",
          feedbackDate: "2021-02-15",
          auctionNumId: "596446991976",
          skuId: "4590028019705",
        },
        {
          feedback: "此用户没有填写评价。",
          userNick: "f**i",
          skuMap: "颜色分类：24瓶混合（纯情+冷艳+诱惑+狂野+浪漫+梦幻各4瓶）",
          feedbackDate: "2021-02-15",
          auctionNumId: "596446991976",
          skuId: "4590028019705",
        },
      ], //竞品评价表格数据

      currentPage: 1, //当前第几页
      total: 5, //表格总条数
      introduce: [
        {
          title: "功能介绍",
          icon: "&#xe60a;",
          content: [
            "快速查找商家的评价，可以查找买家对商品的差评，以便我们快速更改，避免问题再次出现",
          ],
        },
      ], //介绍相关数组
    };
  },
  components: {
    InputView,
    Introduce,
  },
  methods: {
    // 点击底部分页器时
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getGoodsFeedListFn(this.value);
    },
    // 点击搜索时的方法
    getSearchName(value) {
      this.value = value;
      this.$store.commit("alterJumpFlag", true);
      this.$store.commit(
        "alterLoadContent",
        "正在获取商品评价及竞品评价sku的数据"
      );
      this.CanYouClick = false; //不允许再点击搜索
      this.getGoodsFeedListFn(value); //请求商品评价的数据
    },
    // 请求商品评价的数据
    getGoodsFeedListFn(value) {
      this.goodsEntity = [
        {
          picUrl: "",
          goodsName: "",
          nick: "",
          price: "",
        },
      ];
      this.goodsFeedList = [];
      getGoodsFeedList({
        itemid: value, // "596446991976"
        pageNum: this.currentPage, //不知道是什么参数
        type: "good", //不知道是什么参数
      })
        .then((data) => {
          this.goodsEntity = [data.data.goodsEntity];
          this.goodsFeedList = data.data.goodsFeedList;
          this.total = Number(data.data.total);
        })
        .finally(() => {
          this.$store.commit("alterJumpFlag", false);
          this.CanYouClick = true; //允许再次搜索
        });
    },
  },
};
</script>

<style lang="less" scoped>
.bannerContent {
  display: flex;
}

/deep/.table-in > .cell {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
}
.img_floor {
  width: 100px;
  height: 100px;
  background: linear-gradient(0deg, #ceefff, #7bd5ff);
  margin-right: 50px;
}
</style>